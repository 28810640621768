<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo" v-translate>{{  $gettext('Cadastrar mensagem de rodapé dos terminais') }}</h1>

      <div class="box-form mt-5"  style="max-width: 600px;">
        <v-card flat>
          <v-form>
            <v-card-text class="modal-cadastro-form">
              <v-row>
                <v-col dense cols="12" class="modal-cadastro-form-col-imput">
                  <v-textarea
                    outlined
                    v-model="item.msg"
                    label="Mensagem do rodapé dos terminais"
                    rows="3"
                    class="textarea-msg-terminal" />
                </v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" :sm="3">
                  <v-btn type="submit" :color="variables.colorPrimary" class="br-btn" @click.prevent="submit" :loading="loading">Salvar</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
          <!-- <v-card-text>
            Mensagem de rodapé: <strong>{{ item.msg }}</strong>
          </v-card-text> -->
        </v-card> 
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required  } from 'vuelidate/lib/validators'
import variables from '@/assets/styles/helpers/_variables.scss'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
// import validacao from './validacao'

/**
 * MIXIN
 */
// import listagemTables from '@/views/mixins/listagemTables'

const namespaceStore = 'msgRodapeTerminal'

export default {
  name: 'Cancelamento',
  components: {},
  mixins: [validationMixin],
  data: () => ({
    loading: false,
    texto: ''
  }),

  watch: {
    'item.msg' (val) {
      let data = val.split('\n')

      data.forEach(it => it = it.trim())

      if (this.item.msg === this.texto) return
      
      const fraseArra = []
      data.forEach(item => {
        let frase = ''
        let i = 1
        for (let it of item.split('')) {
          if (i % 30 === 0) {
            frase += '\n'
          }
          frase += it
          i++
        }
        fraseArra.push(frase)
      })

      this.texto = fraseArra.join('\n')

      this.item.msg = this.texto
    }
  },

  mounted () {
    this.loading = true
    this.getItens()
      .finally(() => this.loading = false)
  },

  computed: {
    ...mapGetters(namespaceStore, ['item']),
    variables: () => variables
  },

  methods: {
    ...mapActions(namespaceStore, ['getItens', 'cadastrarItem']),

    submit () {
      this.loading = true
      this.cadastrarItem(this.item)
        .then(() => successSnackbar('Mensagem cadastrada com sucesso!'))
        .catch(e => errorSnackbar(e.error))
        .finally(() => this.loading = false)
    }
  },

  validations () {
    return  {
      item: {
        msg: {
          required
        }
      }
    }
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }
  }

  .box-form {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .3);
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }

.textarea-msg-terminal textarea {
  text-align: center;
}

  
.abas {
  margin-left: 10px;
  padding-top: 10px;
  .v-tab {
    font-size: 14px;
  }

  .abas-item {
    background-color: transparent;
    border: 1px solid $colorPrimary;
    color: $colorPrimary !important;
    padding-top: 2px;
    
    &:not(:last-child) {
      margin-right: 10px;
    }

    &:before {
      display: none;
    }

    &.v-tab--active {
      background-color: $colorPrimary;
      color: #FFF !important;
    }

  }
  .v-tabs .v-tabs-slider-wrapper {
    display: none;
  }
}
</style>>
